.form {
    border: 1px solid white;
    padding: 20px;
    background-color:rgba(255, 255, 255, 0.79);
    box-shadow: 2px 2px  black;
    border-radius: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
 
  .Backgroundall {
    background-image:linear-gradient(to right, #00b4db, #0083b0);
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:20px 4px;
    box-sizing: border-box;
    overflow-y: auto;
  }
 
  .input-field {
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 35px;
  }
 
  .button {
    background-image:linear-gradient(to right, #00b4db, #0083b0);
    color: white;
  }
 
  center{font-size: larger;font-weight: 600;color:#0083b0;margin:1% 0% 6% 0%}